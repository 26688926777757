import React from "react";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { CustomCheckBoxComponent } from "@cbex/ui/input";
import { useTheme } from "@cbex/utils/theme";
interface CustomCheckboxControllerProps {
  name: string;
  control: any;
  label?: string;
  setValue?: any;
  form?: any;
  required?: boolean;
  controlDisabled?: boolean;
  onCustomChange?: any;
}

export const CustomCheckboxController: React.FC<
  CustomCheckboxControllerProps
> = ({
  name,
  control,
  setValue,
  label,
  controlDisabled,
  form,
  onCustomChange,
  ...rest
}) => {
  let error = false;
  if (form) {
    error = form.formState.errors[name];
  }

  const theme = useTheme();

  return (
    <FormControl
      sx={{
        color: error ? theme.palette.error.main : undefined,
      }}
      error={!!error}
      size={"small"}
      required
      variant={"outlined"}
    >
      <FormControlLabel
        control={
          <Controller
            name={name}
            render={({ field: { onChange, value } }) => (
              <CustomCheckBoxComponent
                controldisabled={controlDisabled}
                checked={value}
                sx={{ color: error ? theme.palette.error.main : undefined }}
                onChange={(event, checked) => {
                  if (onCustomChange) {
                    onCustomChange(checked);
                  }
                  onChange(event);
                }}
                {...rest}
              />
            )}
            control={control}
          />
        }
        label={label}
      />
      <FormHelperText>
        {error
          ? //@ts-ignore
            error.message
          : ""}
      </FormHelperText>
    </FormControl>
  );
};
