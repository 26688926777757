import React from "react";
import { NumericFormat } from "react-number-format";
import { TextFieldProps } from "@mui/material/TextField";
import CustomTextField from "./CustomTextFieldComponent";
import InputAdornment from "@mui/material/InputAdornment";

type CustomNumberComponentProps = TextFieldProps & {
  displayType?: any;
  onValue?: any;
  isString?: boolean;
  prefix?: string;
  suffix?: string;
  allowNegative?: boolean;
};

export const CustomNumberComponent = React.forwardRef(
  (
    {
      onValue,
      displayType,
      prefix,
      suffix,
      inputMode,
      isString,
      variant = "filled",
      allowNegative = true,
      ...others
    }: CustomNumberComponentProps,
    ref
  ) => {
    const endAdornment = () =>
      suffix ? (
        <InputAdornment position="start">{suffix}</InputAdornment>
      ) : null;
    const startAdornment = () =>
      prefix ? (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ) : null;

    return (
      //@ts-ignore
      <NumericFormat
        customInput={CustomTextField}
        decimalSeparator=","
        thousandSeparator="."
        size="small"
        inputRef={ref}
        variant={variant}
        inputMode={inputMode ? inputMode : "decimal"}
        displayType={displayType ? displayType : "input"}
        onFocus={(event) => {
          event.target.select();
        }}
        InputProps={{
          endAdornment: endAdornment(),
          startAdornment: startAdornment(),
        }}
        {...others}
        onValueChange={(values) => {
          const { formattedValue, value, floatValue } = values;
          if (onValue && !isString) {
            onValue(floatValue);
          } else onValue(value);
        }}
        allowNegative={allowNegative}
      />
    );
  }
);
CustomNumberComponent.displayName = "CustomNumberComponent";
export default CustomNumberComponent;
