"use client";
import React from "react";
import { IamUser } from "@cbex/data-access";
import { AppContext } from "./AppContext";

interface AppContextproviderProps {
  children:
    | Element
    | React.ReactFragment
    | React.ReactPortal
    | boolean
    | null
    | undefined
    | any;
  userData?: IamUser;
}

export interface AppContextState {
  userData?: IamUser;
}

interface ContextState {
  localAppState: AppContextState;
  setLocalAppState: React.Dispatch<React.SetStateAction<AppContextState>>;
}

export const AppContextProvider = (props: AppContextproviderProps) => {
  const { userData } = props;
  const [localAppState, setLocalAppState] = React.useState<AppContextState>({
    userData: userData,
  });
  const context: ContextState = {
    localAppState,
    setLocalAppState,
  };
  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};
export default AppContextProvider;
