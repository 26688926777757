import React from "react";
import { CustomAutoComplete } from "@cbex/ui/input";

import { AutocompleteProps } from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

type CustomAutoCompleteControllerProps<T> = AutocompleteProps<
  T,
  any,
  any,
  any
> & {
  name: string;
  width?: any;
  customID?: string;
  control: any;
  fullObject?: boolean;
  disableRemoveOption?: boolean;
  onCustomChange?: (val: T | string) => void;
};

export const CustomAutoCompleteController = <T extends Record<string, any>>({
  name,
  control,
  customID,
  fullObject,
  multiple,
  disableRemoveOption,
  onCustomChange,
  ...props
}: CustomAutoCompleteControllerProps<T>) => (
  // const {
  //   field,
  //   fieldState: { error },
  // } = useController({ name });
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <CustomAutoComplete<T>
        defaultValue={props.defaultValue}
        onChange={(
          event,
          objectVal: T | NonNullable<string | T> | (string | T)[] | null,
          reason
        ) => {
          if (disableRemoveOption && reason === "removeOption") {
          } else {
            if (multiple) {
              onChange(objectVal);
              onCustomChange?.(objectVal as T);
            } else {
              if (objectVal && customID) {
                onChange((objectVal as Record<string, string>)[customID]);
              } else {
                onChange((objectVal as { id?: string })?.id ?? "");
              }

              if (onCustomChange) {
                if (fullObject) {
                  onCustomChange(objectVal as T);
                } else {
                  onCustomChange(
                    customID && objectVal
                      ? (objectVal as Record<string, string>)[customID]
                      : (objectVal as { id?: string })?.id ?? ""
                  );
                }
              }
            }
          }
        }}
        //@ts-ignore
        name={name}
        value={value}
        multiple={multiple}
        control={control}
        {...props}
      />
    )}
  />
);
